
export default {
	name: "CoreBlockBreadcrumbs",
	props: {
		settings: {
			type: Object,
			default: () => {},
		},
		dataSite: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			windowWidth: process.client ? window.innerWidth : null,
			isCollapsed: true,
		};
	},
	computed: {
		navItems() {
			return this.dataSite?.navigation?.links || [];
		},
		crumbs() {
			// This is the breadcrumb list used by the builder.
			if (this.settings.breadcrumbList?.length) {
				return this.settings.breadcrumbList;
			}

			// The following is used by the generator.
			const fullPath = this.$route.fullPath;
			const params = fullPath.startsWith("/")
				? fullPath.substring(1).split("/")
				: fullPath.split("/");

			// This is for the home page. If we need a breadcrumb at this page, we should return an object here.
			if (!params.length || (params.length === 1 && params[0] === "")) {
				return [];
			}

			// Here we start generating the breadcrumb based on the current route path.
			const newBreadcrumbList = [];
			const selectedPageHandle = params[params.length - 1];
			let path = ''
			for (const param of params.filter( p => p !== '') ) {
				path = `${path}/${param}`;
				const match = this.$router.match(path);
				const page = this?.dataSite?.pages.find(
					(p) => p.handle.replace(/^\/|\/$/g, "") === decodeURI(param)
				);

				// In the case we don't find a page, we return an element based on the path.
				if (!page) {
					newBreadcrumbList.push({
						title: param,
						pageId: param,
						selectedPageId: selectedPageHandle,
						path: `/${param}`,
					});
					continue;
				}

				newBreadcrumbList.push({
					title: page.title,
					pageId: page.handle,
					selectedPageId: selectedPageHandle,
					path: match?.path || this.getPathForPage(page.handle),
					linkUrl: `/${page.handle}`,
				});
			}

			return newBreadcrumbList;
		},
		variants() {
			return this.settings?.variants || {};
		},
		isCompact() {
			return this.variants.breadcrumbVariant === "compact";
		},
		crumbClasses() {
			let classes = "breadcrumb";
			if (this.isCompact) {
				classes = `${classes} breadcrumb__compact`;
			}
			return classes;
		},
		listClasses() {
			let classes = "breadcrumb__list-item";
			if (this.isCompact) {
				classes = `${classes} breadcrumb__list-item--compact`;
			}
			return classes;
		},
	},
	created() {
		if (process.client) {
			this.windowWidth = window.innerWidth;
			window.addEventListener("resize", this.onResize);
		}
	},
	destroyed() {
		if (process.client) {
			window.removeEventListener("resize", this.onResize);
		}
	},
	methods: {
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		setIsCollapsed(value) {
			this.isCollapsed = value;
		},
		getPathForPage(pageHandle, pagePath = "") {
			const page = this.dataSite?.pages?.find(
				(p) => p.handle === pageHandle || p.id === pageHandle
			);

			if (!page) {
				return pagePath ?? "/";
			}

			pagePath = `/${page.handle}${pagePath}`;

			if (page.parentPageId || page.newFieldForParentId) {
				return this.getPathForPage(
					page.parentPageId || page.newFieldForParentId,
					pagePath
				);
			}
			return pagePath;
		},
		isFolder(crumb) {
			const navItem = this.navItems.find(
				(nav) => nav.linkUrl === crumb.linkUrl
			);
			if (!navItem) {
				return true;
			}

			if (navItem.isFolder) {
				return true;
			}
			return false;
		},
	},
};
